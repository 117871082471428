import { cn } from '@/lib/utils';
import { Link, LinkProps } from 'react-router';
import { forwardRef } from 'react';

export const Hyperlink = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        className={cn(
          'text-blue-700 hover:underline hover:text-blue-800',
          className
        )}
        {...props}
      >
        {children}
      </Link>
    );
  }
);

Hyperlink.displayName = 'Hyperlink';
